<template>
  <a-drawer
    :title="titleText[handleType]"
    :maskClosable="false"
    width="650"
    placement="right"
    @close="handleClose()"
    :visible="drawerShow"
    :closable="false"
    style="height: calc(100% - 55px); overflow: auto; padding-bottom: 53px"
  >
    <div>
      <a-form-model
        ref="ruleForm"
        :validateOnRuleChange="true"
        :model="form"
        :rules="formRules"
        :form="form"
        :label-col="{ span: 3 }"
        :wrapper-col="{ span: 18 }"
      >
        <a-form-model-item label="文件名称" prop="fileName">
          <a-input placeholder="输入文件名称" v-model="form.fileName" />
        </a-form-model-item>
        <a-form-model-item label="文件详情" prop="fileUrl">
          <upload-enclosure
            :autoPath="true"
            :module="'training'"
            :enclosure-list="enclosureList"
            @_change="handleUploadEnclosureChange"
          ></upload-enclosure>
        </a-form-model-item>
      </a-form-model>
    </div>

    <div class="drawer-bootom-button">
      <!-- <a-popconfirm
        title="确定放弃编辑？"
        okText="确定"
        cancelText="取消"
        @confirm="handleClose()"
      >
        <a-button style="margin-right: 0.8rem">取消</a-button>
      </a-popconfirm> -->
			<a-button @click="handleClose()" style="margin-right: 0.8rem">取消</a-button>
      <a-button type="primary" @click="handleSubmit" :loading="loading">{{
        handleType === 0 ? "添加" : "编辑"
      }}</a-button>
    </div>
  </a-drawer>
</template>
<script>
import UploadEnclosure from "@/components/upload-enclosure/UploadEnclosure";
const formRules = {
  fileName: [{ required: true, message: "请输入文件名称", trigger: "blur" }],
  fileUrl: [{ required: true, message: "请选择文件", trigger: "blur" }],
};
export default {
  components: { UploadEnclosure },
  data() {
    return {
      form: {
        id: undefined,
        fileName: undefined,
        fileType: undefined,
        fileUrl: undefined,
      },
      loading: false,
      formRules,
      titleText: ["新增文件", "修改文件"],
      handleType: 0,
      drawerShow: false,
      enclosureList: [],
    };
  },
  methods: {
    handleUploadEnclosureChange(list, urls) {
      console.log("上传附件的返回值===>", list, urls);
      this.enclosureList = list;
      // this.form.video = JSON.stringify(urls);
      // list[0].type 可以取到后缀
      this.form.fileType = list[0].type
      if(urls && urls.length !== 0){
        this.form.fileUrl = urls[0].url
      }
    },
    reset() {
      this.$refs.ruleForm.resetFields();
      for (let key in this.form) {
        this.form[key] = undefined;
      }
      this.enclosureList = []
    },
    handleClose() {
      this.drawerShow = false;
      this.loading = false;
      this.reset();
    },
    setForm(handleType, data) {
      this.handleType = handleType;
      if (!handleType) {
        for (let key in this.form) {
          this.form[key] = undefined;
        }
      }else{
        for (let key in data) {
          this.form[key] = data[key];
        }
        let file = {
          uid:'-1',
          name:undefined,
          status:'done',
          url:undefined
        }
        // debugger
        let index = data.fileUrl.lastIndexOf(".");
        let suffix = data.fileUrl.substring(index);
        file.name = data.fileName + suffix
        file.url = data.fileUrl
        this.enclosureList.push(file)
      }
      this.drawerShow = true;
    },
    handleSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          let file = JSON.parse(JSON.stringify(this.form));
          //新增操作
          if (this.handleType === 0) {
            this.$post("training/file", {
              ...file,
            }).then((r) => {
              this.$message.success(r.data.message);
              this.$emit("success");
              this.handleClose();
              console.log("success", r);
            });
          } else if (this.handleType === 1) {
              this.$put("training/file", { ...file }).then((r) => {
              this.$message.success(r.data.message);
              this.handleClose();
              this.$emit("success");
              console.log(r);
            });
          }
        }
      });
      console.log("submit");
    },
  },
};
</script>